export type Preferences = {
	// shows the percentage progression by default on progress bars, instead of the current step and step count
	progressShowPercent: boolean

	// shows the estimated duration in approximation (in a few seconds) by default instead of the actual expected time
	progressShowApproximateEstimate: boolean

	// shows the current step in progresses
	progressShowStep: boolean

	// shows holidays and school breaks on step 8 only when they intersect with the service
	calendarOnlyIntersects: boolean

	// show paginator and element counts on the bottom of tables instead of the top
	tableExtrasBottom: boolean

	// automatically unselects elements after an edit or revert operation on a table
	tableAutoUnselect: boolean

	// select only the current page elements in table
	tableSelectAllPageOnly: boolean

	// set user modal "settings" target to settings page
	userSettingsMenu: boolean

	// use a dark theme for the app instead of the default light one
	themeDark: boolean

	// hide admin powers
	hideAdmin: boolean

	// show the project indicator at the top right of the screen
	projectIndicator: boolean

	// show the dev info at the top right of the screen
	devInfo: boolean

	// enable hidden prefs
	hidden: boolean

	// simple mode for the navbar
	navbarSimple: boolean
}
export type Preference = keyof Preferences

export const defaultPreferences: Preferences = Object.freeze({
	progressShowPercent: true,
	progressShowApproximateEstimate: true,
	progressShowStep: false,
	calendarOnlyIntersects: false,
	tableExtrasBottom: false,
	tableAutoUnselect: false,
	tableSelectAllPageOnly: false,
	userSettingsMenu: false,

	themeDark: false,

	hideAdmin: false,

	projectIndicator: false,
	devInfo: false,
	hidden: false,

	navbarSimple: false,
})

export const preferenceValidation: {
	[K in keyof Preferences]: (x: any) => boolean
} = {
	progressShowPercent: x => typeof x == 'boolean',
	progressShowApproximateEstimate: x => typeof x == 'boolean',
	progressShowStep: x => typeof x == 'boolean',
	calendarOnlyIntersects: x => typeof x == 'boolean',
	tableExtrasBottom: x => typeof x == 'boolean',
	tableAutoUnselect: x => typeof x == 'boolean',
	tableSelectAllPageOnly: x => typeof x == 'boolean',
	userSettingsMenu: x => typeof x == 'boolean',
	themeDark: x => typeof x == 'boolean',
	hideAdmin: x => typeof x == 'boolean',
	projectIndicator: x => typeof x == 'boolean',
	devInfo: x => typeof x == 'boolean',
	hidden: x => typeof x == 'boolean',
	navbarSimple: x => typeof x == 'boolean',
}

export function parsePrefs(value: any): Preferences {
	try {
		if (typeof value == 'function') return parsePrefs(value())
		if (typeof value == 'object') return parsePrefs(JSON.stringify(value))
		if (typeof value != 'string') return defaultPreferences
		const data = JSON.parse(value)
		const prefs: Partial<Preferences> = {}
		for (const [k, val] of Object.entries(preferenceValidation)) {
			if (k in data && val(data[k])) {
				prefs[k as Preference] = data[k]
			} else {
				prefs[k as Preference] = defaultPreferences[
					k as keyof Preferences
				] as any
			}
		}
		return prefs as Preferences
	} catch (e) {
		console.warn('Could not parse preferences', e)
		return defaultPreferences
	}
}
