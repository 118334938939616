import { eq } from '@lc/libutils'

import {
	defaultPreferences,
	parsePrefs,
	preferenceValidation,
	type Preference,
	type Preferences,
} from '@horfix/horfix-common/types/data/prefs'

let preferences = parsePrefs(() => localStorage.getItem('horfix-prefs'))
try {
	localStorage.setItem('horfix-prefs', JSON.stringify(preferences))
} catch (e) {
	console.warn(`Preferences cannot be saved`, e)
}

const globalListeners = new Set<() => void>()
const keyListeners = new Map<Preference, Set<() => void>>(
	Object.keys(preferences).map(key => [key as Preference, new Set()]),
)

export function getPreferences(): Preferences {
	return { ...preferences }
}
export function getPreference<K extends Preference>(key: K): Preferences[K] {
	return preferences[key]
}

export function onPreferences(handler: () => void) {
	globalListeners.add(handler)
}
export function offPreferences(handler: () => void) {
	globalListeners.delete(handler)
}
export function onPreference(pref: Preference, handler: () => void) {
	keyListeners.get(pref)?.add(handler)
}
export function offPreference(pref: Preference, handler: () => void) {
	keyListeners.get(pref)?.delete(handler)
}

export function setPreference<K extends Preference>(
	key: K,
	value: Preferences[K],
) {
	if (!preferenceValidation[key](value)) return
	if (eq(value, preferences[key])) return

	preferences = { ...preferences, [key]: value }
	try {
		localStorage.setItem('horfix-prefs', JSON.stringify(preferences))
	} catch (e) {
		console.warn(`Preferences cannot be saved, key ${key} will be lost`, e)
	}

	globalListeners.forEach(x => x())
	keyListeners.get(key)?.forEach(x => x())
}

export function setPreferences(newPrefs: Preferences) {
	const oldPrefs = preferences

	for (const [k, val] of Object.entries(preferenceValidation)) {
		if (!val(newPrefs[k as Preference])) return
	}

	let changed = false
	preferences = newPrefs
	for (const [k, newValue] of Object.entries(newPrefs)) {
		const key = k as Preference
		const oldValue = oldPrefs[key]

		if (eq(newValue, oldValue)) continue
		changed = true
		keyListeners.get(key)?.forEach(x => x())
	}

	if (!changed) return
	globalListeners.forEach(x => x())
}

export function clearPreferences() {
	setPreferences(defaultPreferences)
	try {
		localStorage.removeItem('horfix-prefs')
	} catch (e) {
		console.warn(`Preferences cannot be cleared`, e)
	}
}

addEventListener('storage', e => {
	if (e.key === 'horfix-prefs' && e.storageArea === localStorage) {
		setPreferences(parsePrefs(e.newValue))
	}
})

Object.assign(window, {
	prefs: Object.defineProperty(
		{
			get: (key?: Preference) =>
				key ? getPreference(key) : getPreferences(),
			set: <K extends Preference>(key: K, value: Preferences[K]) =>
				setPreference(key, value),
		},
		'all',
		{ get: () => getPreferences() },
	),
})
