import type * as appModuleType from './App'

import { enableMapSet } from 'immer'
import React, { StrictMode } from 'react'
import ReactDOM, { createRoot } from 'react-dom/client'
import { Router } from 'wouter'

import Spinner from './components/Spinner'
import { WsContextProvider } from './contexts/WsContext'
import { setAuth } from './data/auth'
import { setContactLink } from './data/config'
import { loadProject, updateGen } from './data/helpers'
import { getPreference, onPreference, setPreferences } from './data/prefs'
import { clearProject } from './data/project'
import { Provider, store } from '@/data/store'
import { commit, version, date } from './generated/version'
import {
	reactHistory,
	useCancellableLocation,
} from './hooks/useCancellableLocation'
import { apiCall } from './util/apiCall'
import { sendBroadcast } from './util/broadcast'

import { parsePrefs } from '@horfix/horfix-common/types/data/prefs'

import './index.scss'

// don't force a full refresh on version update
if ((module as any).hot) {
	;(
		module as unknown as {
			hot: { accept(mod: string, cb?: () => void): void }
		}
	).hot.accept('./generated/version', () => {
		console.log('caught version update', { commit, version, date })
	})
}

// enable Map and Set support in immer
enableMapSet()

// expose vendor dependencies
Object.assign(window, { React, ReactDOM })

// expose debug
Object.assign(window, { debug: process.env.DEBUG === '1' })

// expose store, state and history
Object.assign(window, { store })
Object.defineProperty(window, 'state', { get: () => store.getState() })
Object.assign(window, { reactHistory })

// setup theme based on preferences
{
	const updateTheme = () => {
		const darkTheme = getPreference('themeDark')
		document.body.dataset.theme = darkTheme ? 'dark' : 'light'
	}
	updateTheme()
	onPreference('themeDark', updateTheme)
}

// create app root
const root = createRoot(document.querySelector('#app-root')!)

// render a spinner while the app is loading
root.render(
	<main
		style={{
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		}}>
		<Spinner />
	</main>,
)

// initialize history
Object.assign(reactHistory, [location.pathname])

// load the app and everything required for it to start
let appModule: typeof appModuleType | null = null
let currentVersion: { commit: string; version: string } | null = null
await Promise.all([
	// check login and project
	(async () => {
		try {
			const { email, displayName, uuid, admin, expires, project, prefs } =
				await apiCall('/auth/check', { type: true })
			store.dispatch(
				setAuth({
					email,
					displayName,
					uuid,
					admin,
					expires,
					project,
				}),
			)
			setPreferences(parsePrefs(prefs))
			sendBroadcast('login', {
				email,
				displayName,
				uuid,
				admin,
				expires,
				project,
			})

			if (!project) {
				store.dispatch(clearProject())
				return
			}

			await Promise.all([
				loadProject(store.dispatch),
				updateGen(store.dispatch),
			])
		} catch (e) {
			if (debug) console.error(e)
		}
	})(),

	// load app code
	(async () => {
		appModule = await import(/* webpackChunkName: 'app' */ './App')
	})(),

	// load current version
	(async () => {
		if (process.env.LIVE) {
			currentVersion = { commit, version }
			return
		}
		const res = await fetch('/version.json', { cache: 'no-store' })
		currentVersion = await res.json()
	})(),

	// load contact link
	(async () => {
		const { contactLink } = await apiCall(
			'/config/helpConfig/contactLink',
			{
				type: true,
			},
		)
		store.dispatch(setContactLink(contactLink))
	})(),
])

const { commit: currentCommit, version: currentVersionNumber } = currentVersion!
if (currentCommit !== commit || currentVersionNumber !== version) {
	if (
		typeof sessionStorage === 'undefined' ||
		sessionStorage.getItem('horfix-version-restarted') === commit
	) {
		root.render(
			<main
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
					height: '100%',
				}}>
				<h1
					style={{
						fontWeight: 'bold',
						fontSize: '3rem',
						marginBottom: '1rem',
					}}>
					Nouvelle version de LC SaaS Fiches Horaires disponible!
				</h1>
				<p
					style={{
						fontSize: '2rem',
						marginBottom: '1rem',
					}}>
					Veuillez recharger la page pour l&apos;appliquer.
				</p>
				<small
					style={{
						opacity: '0.5',
					}}>
					(Il peut être nécessaire de vider le cache de votre
					navigateur)
				</small>
			</main>,
		)
		throw new Error('Cache issue, see DOM')
	} else {
		sessionStorage.setItem('horfix-version-restarted', commit)
		location.reload()
	}
}

// render app
const App = appModule!.default
root.render(
	<StrictMode>
		<Provider>
			<WsContextProvider>
				<Router hook={useCancellableLocation}>
					<App />
				</Router>
			</WsContextProvider>
		</Provider>
	</StrictMode>,
)
