import type { PayloadAction } from '@reduxjs/toolkit'

import type { Branch } from '@horfix/horfix-common/types/data/data'

import { createSlice } from '@reduxjs/toolkit'

export type ProjectFlag =
	| 'fhp'
	| 'fhl'
	| 'fip'
	| 'pmr'
	| 'poi'
	| 'depositaires'
	| 'qr'
	| 'branches'
	| 'kiosk'
	| 'fhpfrequency'
export const projectFlags: ProjectFlag[] = [
	'fhp',
	'fhl',
	'fip',
	'pmr',
	'poi',
	'depositaires',
	'qr',
	'branches',
	'kiosk',
	'fhpfrequency',
]

export type ProjectState = {
	uuid: string | null
	name: string | null
	logo: string | null
	current_branch: string | null
	branches: Branch[]
	flags: ProjectFlag[]
}
const initialProjectState: ProjectState = {
	uuid: null,
	name: null,
	logo: null,
	current_branch: null,
	branches: [],
	flags: [],
}

const projectSlice = createSlice({
	name: 'project',
	initialState: initialProjectState,
	reducers: {
		clearProject: () => {
			return initialProjectState
		},
		setProject: (
			_state,
			{ payload }: PayloadAction<Omit<ProjectState, 'current_branch'>>,
		) => {
			const visible = filterVisibleBranches(payload.branches)
			return {
				...payload,
				current_branch: visible.length === 1 ? visible[0].branch : null,
			}
		},
		setProjectName: (state, { payload }: PayloadAction<string>) => {
			state.name = payload
		},
		setProjectFlags: (state, { payload }: PayloadAction<ProjectFlag[]>) => {
			state.flags = payload
		},
	},
})

export function isBranchVisible(branch: Branch): boolean {
	if (branch.readonly) return false
	if (branch.extra.hidden) return false
	if (branch.extra.frozenGen) return false
	if (branch.extra.frozenGenType) return false
	return true
}
export function filterVisibleBranches(branches: Branch[]): Branch[] {
	return branches.filter(isBranchVisible)
}

export const { clearProject, setProject, setProjectName, setProjectFlags } =
	projectSlice.actions
export default projectSlice.reducer
